.dz{
    .lessonName{
        background:antiquewhite;
        
    }
} 
.en {
    .lessonName{
        background:antiquewhite;
    }
}
