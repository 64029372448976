

@font-face {
    font-family: Wangdi29;
    font-size:2.5rem;
    src: url("../fonts/Wangdi_29.otf") format("opentype");
}

@font-face {
    font-family: Jomolhari;
    src: url("../fonts/Jomolhari.otf") format("opentype");
}

@font-face {
    font-family: DDC_Uchen;
    src: url("../fonts/DDC_Uchen.otf") format("opentype");
}

.ddcuchen{
    font-family: DDC_Uchen;
    font-size: 1.1rem;
}
.jomolhari{
    font-family: jomolhari;
    font-size: 1.5rem;
}

.wangdi29{
    font-family: Wangdi29;
    font-size: 2.5rem;
    .flag-select__btn{
        font-size:2.5rem !important;
    }
    .flag-select__option__icon{
        width: 1em;
        height: 1em;
    }
    .flag-select__option__label{
        font-size:2rem !important;
    }
    #select_flag_US{
        
        .flag-select__option__label{
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
            font-size:1rem !important;
        }
    }
}
body{
    font-size: 1rem;
    line-height: normal;
}
.welcome{
    text-align:center;
    .title{
        padding:10px;
    }
    
}
.backgroundimage{
    position:absolute;
    top:-580px;
    bottom:0px;
    left:0px;
    right:0px;
    width:100%;
    z-index:-999;
    
}
.dz{
    // font-family: Arial, Helvetica, sans-serif;

    h2{
        font-size: 4rem;
        font-weight: 500;
        margin-bottom: -20px;
        font-family: Wangdi29;
    }
    .flag-select__option__icon{
        top:0;
        position:none;
    }
    // .language-switcher{
    //     float:right;
    // }
    .title-container{
        height:55px;
        position:relative;
        width:100%;
        // background: #0ae;
        div{
            position:absolute;
            left:0;
            right:0;
            top:0;
            bottom:0;
            width:100%;
            height:100%;
        }
        .title{
            position:relative;
            font-size:1.8rem;
            margin-top:5px;
            width:100%;
            text-align:center;
        }
        .title.wangdi29{
            font-size:4rem;
        }
    }
    .menu-container{
        height:50px;
        .dropdown-submenu-level-1{
            .dropdown-menu.show{
                // left:100% !important;
                // top:-9px !important;
                transform: translate(100%, -10px) !important;
            }
        }
        .wangdi29{
            
            .dropdown-submenu-level-1, .dropdown-submenu-level-2, .dropdown-submenu-level-3{
                font-size:2rem;
                .dropdown-toggle::after{
                    font-size:1rem;
                    display: inline-block;
                    margin-left: 0.255em;
                    vertical-align: 0.255em;
                    margin-top:0em;
                    content: "";
                    border-top: 0.3em solid;
                    border-right: 0.3em solid transparent;
                    border-bottom: 0;
                    border-left: 0.3em solid transparent;
                }
                .dropdown-item{
                    font-size:2rem;
                }
            }
        }
    }
}
.en{
    margin-top:0px;
    
    .title-container{
        height:55px;
        position:relative;
        width:100%;
        // background: #0ae;
        div{
            position:absolute;
            left:0;
            right:0;
            top:0;
            bottom:0;
            width:100%;
            height:100%;
        }
        .title{
            position: relative;
            top: 7px;
            width: 100%;
            font-size: 2rem;
            text-align: center;
        }
    }
    h2{
        font-size:1.4rem;
    }
    .flag-select__option__icon{
        top:0;
        position:none;
    }
    // .language-switcher{
    //     float:right;
    // }

    .menu-container{
        height:50px;
        .dropdown-submenu-level-1{
            .dropdown-menu.show{
                // left:100% !important;
                // top:-9px !important;
                transform: translate(100%, -10px) !important;
            }
        }
    }
    
}

.progressbar{
    margin-top:5px;
}