.hands-container{
    position: relative;
    text-align:center;
    margin-top:100px;
    height: 200px;
    overflow: hidden;

    .left-hand{
        height: 300px;
        width: 300px;
        margin-left: -70px;
        opacity: 1;
    }
    .right-hand{
        height:300px;
        width:300px;
        opacity:1
    }
}