
.dz{
    .playground{
        .next-one-character{
            width:50px;
            height:50px;
            border-radius: 55px;
            display:block;
            background:red;
            color:white;
            text-align: center;
            margin: auto;
        }
    
        .lesson-content-box{
            margin-top:10px;
            padding-top:10px;
            border: 1px solid;
            
        }
        .lesson-content{
            margin-left: 10px;
            margin-right: 10px;
            height: 100px;
            overflow: hidden;
            text-align: center;
        }
        .Character-current{
            //  border: 1px solid red;
            color: white;
            background-color: #f90000;
        }
        .Character{
            margin-bottom:0px;
            white-space: pre;
            font-size:1.35rem;
            
        }
        
        .Next-character{
            color:#000
        }
        .Character-out{
            color:#bdbbb9;
        }
        .summary{
            margin-top:20px;
            margin-bottom:10px;
            .speedlabel{
                color:#41ad48;
            }
            .speedvalue{
                color:#41ad48;
            }
            .errorlabel{
                color:#c15a18;
            }
            .errorvalue{
                color: #c15a18;
            }
            .accuracylabel{
                color:#c6970d;
            }
            .accuracyvalue{
                color:#c6970d;
            }
        }
        .control-buttons-container{
            text-align:right;
            margin-top:10px
        }
    }
    
    .progress-bar{
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;
        color: #fff;
        text-align: center;
        white-space: nowrap;
        background-color: #007bff;
        transition: width 0.6s ease;
        font-size:1.2rem;
    }
    .wangdi29{
        .Character{
            font-size:4rem;
        }
        .progress-bar{
            font-size:2rem;
        }
    }

    .jomolhari{
        .Character{
            font-size:1.9rem;
        }
        .progress-bar{
            font-size:2rem;
        }
    }
}


.en{
    .playground{
        .next-one-character{
            width:50px;
            height:50px;
            border-radius: 55px;
            display:block;
            background:red;
            color:white;
            margin: auto;
            text-align: center;
        }
    
        .lesson-content-box{
            margin-top:10px;
            padding-top:10px;
            border: 1px solid;
            
        }
        .lesson-content{
            margin-left: 10px;
            margin-right: 10px;
            overflow: hidden;
            height: 100px;
            text-align: center;
        }
        .Character-current{
            //  border: 1px solid red;
            color: white;
            background-color: #f90000;
        }
        .Character{
            margin-bottom:0px;
            white-space: pre;
            font-size:2rem;
        }
        .Next-character{
            color:#000
        }
        .Character-out{
            color:#bdbbb9;
        }
        .summary{
            font-size:1.2rem;
            margin-top:25px;
            margin-bottom:10px;
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
            .speedlabel{
                font-weight: bold;
                color:#41ad48;
            }
            .speedvalue{
                color:#41ad48;
            }
            .errorlabel{
                font-weight:bold;
                color:#c15a18;
            }
            .errorvalue{
                color: #c15a18;
            }
            .accuracylabel{
                font-weight:bold;
                color:#c6970d;
            }
            .accuracyvalue{
                color:#c6970d;
            }
        }
        .control-buttons-container{
            text-align:right;
            margin-top:0px
        }
    }
    .wangdi29{
        .Character{
            font-size:4rem;
        }
    }
}